import {
  BEMER_DOMAINS,
  defaultLocale,
  deleteVisitorSettings,
  doesPartnerHandleExist,
  getCountryCodeFromLocale,
  getDomainForLocation,
  getVisitorSettings,
  IGNORED_PARTNER_HANDLES,
  IGraphqlLocaleIdentifier,
  ILocale,
  TLocaleId,
  updateVisitorSettings,
} from '@bemer/base';
import { navigate } from 'gatsby';
import { useQuery, useQueryClient } from 'react-query';

interface IPropsVisitorSettings {
  allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[];
  doPartnerHandleRedirect: boolean;
  locale?: ILocale;
}

const visitorServiceIsActive =
  process.env.GATSBY_DISABLE_VISITOR_SERVICE !== 'true';

const DOMAINS_FOR_REGEXP = BEMER_DOMAINS.map((domain) => `(?:${domain})`)
  .join('|')
  .replace(/\./g, '\\.');
const REGEXP_GET_URL_PARTNER_HANDLE = new RegExp(
  `^([\\w-]*)\\.(?:${DOMAINS_FOR_REGEXP})`
);

const getPartnerHandleFromHostname = (hostname: string): undefined | string => {
  const partnerHandleFromUrl = hostname.match(
    REGEXP_GET_URL_PARTNER_HANDLE
  )?.[1];

  if (!partnerHandleFromUrl) {
    return undefined;
  }
  if (IGNORED_PARTNER_HANDLES.includes(partnerHandleFromUrl)) {
    return undefined;
  }
  return partnerHandleFromUrl;
};

/**
 * Helper to get the route of the startPage for the given localeId.
 * By convention, the root is always the locale id. (www.domain.com/de_CH, www.domain.com/fr_FR, ...)
 */
const getLocaleStartPageRoute = (
  localeId: TLocaleId,
  allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[]
): string => {
  // 1. Filter all active LocaleIdentifiers for the CountryCode.
  const filteredLocaleIdentifiers = allActiveLocaleIdentifiers.filter(
    (localeIdentifier) =>
      localeIdentifier.country.countryCode ===
      getCountryCodeFromLocale(localeId)
  );

  // 2. If no LocaleIdentifier was found, return the DefaultLocales' id as the startPageRoute.
  if (filteredLocaleIdentifiers.length === 0) {
    return defaultLocale.id;
  }

  // 3. Search for the exact localeId.
  // Fall back to the default Locale in that country, or take the first available one.
  return (
    filteredLocaleIdentifiers.filter(
      (localeIdentifier) => localeIdentifier.localeId === localeId
    )[0] ||
    filteredLocaleIdentifiers.filter(
      (localeIdentifier) => localeIdentifier.default
    )[0] ||
    filteredLocaleIdentifiers[0]
  ).localeId;
};

/**
 * Helper to build a valid URL for a given hostname.
 * Keeps the current protocol and current pathname.
 */
const buildHref = (hostname: string) => {
  const url = new URL(window.location.href);
  url.hostname = hostname;
  return url.href;
};

/**
 * Helper to change the window location href to the given value.
 */
const redirect = (href: string): void => {
  window.location.href = href;
};

/**
 * Component to include in each page to handle checks against the visitor service.
 *
 * It gets all data for the visitor from the backend an checks for 2 things:
 * 1. If the page has no locale, navigate to the stored local in the visitorSettings.
 * 2. It gets the visitors preferred partner handle from the backend and redirects
 *     to that handle if no, or another handle is found in the URL.
 *     If no handle is stored in the backend and the URL contains a handle, this handle
 *     is stored in the backend for future visits.
 */
const VisitorSettings = ({
  allActiveLocaleIdentifiers,
  doPartnerHandleRedirect = false,
  locale,
}: IPropsVisitorSettings): null => {
  // Do nothing when SSR or when VisitorService deactivated via env-variable.
  if (typeof window === 'undefined' || !visitorServiceIsActive) {
    return null;
  }

  const queryClient = useQueryClient();

  // Get partner handle of the current URL.
  const partnerHandleFromUrl = getPartnerHandleFromHostname(
    window.location.hostname
  );

  // 1. Create all react hooks (never put hooks in conditional statements!)
  // - Get all information the backend has stored for this visitor.
  // - Check if the partner handle from the URL is valid against partnerfinder.
  const { isLoading: isLoadingGetVisitorSettings, data: visitorSettings } =
    useQuery('getVisitorSettings', () => getVisitorSettings());

  const {
    isLoading: isLoadingPartnerHandleFromUrlCheck,
    data: isPartnerHandleFromUrlValid,
  } = useQuery(
    ['doesPartnerHandleFromUrlExist', partnerHandleFromUrl, locale],
    () => doesPartnerHandleExist(partnerHandleFromUrl, locale)
  );

  const {
    isLoading: isLoadingPartnerHandleFromVisitorSettingsCheck,
    data: isPartnerHandleFromVisitorSettingsValid,
  } = useQuery(
    [
      'doesPartnerHandleFromVisitorSettingsExist',
      visitorSettings?.partnerHandle,
      locale,
    ],
    () => doesPartnerHandleExist(visitorSettings?.partnerHandle, locale)
  );

  if (
    isLoadingGetVisitorSettings ||
    visitorSettings === undefined ||
    isLoadingPartnerHandleFromUrlCheck ||
    isLoadingPartnerHandleFromVisitorSettingsCheck ||
    isPartnerHandleFromVisitorSettingsValid === undefined ||
    isPartnerHandleFromUrlValid === undefined
  ) {
    return null;
  }
  // 2. Check the locale.
  // If the page does not contain a locale (it's probably the rootPage in that case),
  // go find the startPage for the locale given by the backend for this visitor
  // and navigate to that page.
  if (locale === undefined && visitorSettings.locale) {
    navigate(
      getLocaleStartPageRoute(
        visitorSettings.locale,
        allActiveLocaleIdentifiers
      )
    );
    return null;
  }

  // 3. Check partner handle.

  // 3.1. If the URL partner handle and the visitorService partner handle
  // are the same and valid (also if both are 'undefined'/'null'),
  // do nothing.
  if (
    (!partnerHandleFromUrl && !visitorSettings.partnerHandle) ||
    (partnerHandleFromUrl === visitorSettings.partnerHandle &&
      isPartnerHandleFromUrlValid)
    // maybe isValid here
  ) {
    return null;
  }

  // 3.2. If they do not match ...
  // Get the hostname without a (potential) partner handle.
  const hostnameWithoutHandle = getDomainForLocation(window.location);

  // 3.2.1 ...use the handle from the backend to overwrite any partner handle in the URL.
  if (
    doPartnerHandleRedirect &&
    visitorSettings.partnerHandle &&
    isPartnerHandleFromVisitorSettingsValid
  ) {
    redirect(
      buildHref(`${visitorSettings.partnerHandle}.${hostnameWithoutHandle}`)
    );
    return null;
  }

  // 3.2.2 ... do nothing if there is no partner handle in the URL.
  if (!partnerHandleFromUrl) {
    return null;
  }

  // 3.2.3 ... save the handle from the URL in the backend if there is none in the backend yet and
  // if the handle is valid

  // 3.2.3.1 If the partner handle is not valid, remove it from the URL.
  if (doPartnerHandleRedirect && !isPartnerHandleFromUrlValid) {
    deleteVisitorSettings(['partnerHandle']).then(() =>
      redirect(buildHref(hostnameWithoutHandle))
    );
    return null;
  }

  // 3.2.3.2 If the partner handle is valid, save it in the backend and update the cached query.
  updateVisitorSettings({
    partnerHandle: partnerHandleFromUrl,
  }).then((updatedVisitorSettings) => {
    queryClient.setQueryData(['getVisitorSettings'], updatedVisitorSettings);
  });

  return null;
};

export {
  VisitorSettings,
  IPropsVisitorSettings,
  getPartnerHandleFromHostname,
  getLocaleStartPageRoute,
};
